<template>
  <div>
    <div
      v-if="!esProfesor"
      class="d-flex justify-content-start align-items-center"
    >
      <b-button
        class="mr-1"
        variant="outline-primary"
        style="padding: 5px 10px"
        :to="{ name: breadcrumb[1].to.name, params: { id: $route.params.globalId } }"
      >
        <feather-icon
          icon="ChevronsLeftIcon"
        />
        volver
      </b-button>
      <span class="border-right pr-1 mr-1"><h3>{{ pageTitle }}</h3></span>
      <feather-icon
        icon="HomeIcon"
        class="text-primary"
        size="16"
      />
      <feather-icon
        icon="ChevronRightIcon"
      />
      <b-breadcrumb
        :items="breadcrumb"
      />
    </div>
    <br>
    <!-- Breadcrumb End -->

    <modalAddEstudiante
      :estudiantes-taller="estudiantesTaller"
      :codigo="codigo"
      @actualizarTablaEstudiantes="actualizarTablaEstudiantes"
    />
    <modal-evaluacion
      :codigo="codigo"
      @actualizarTablaEstudiantes="actualizarTablaEstudiantes"
    />
    <b-card class="mb-1 pl-1">
      <b-row>
        <b-col
          lg="7"
          md="7"
          sm="12"
        >
          <h5 class="">
            <feather-icon
              icon="PlayIcon"
            />
            <strong class="text-primary">{{ propNameTaller }} : </strong>{{ dataTaller.nombre }}
            ( <b-badge
              :variant="(dataTaller.estado == 1) ? 'light-success' : 'light-danger'"
              class="px-2"
            >
              {{ (dataTaller.estado == 1) ? 'ACTIVO' : 'INACTIVO' }}
            </b-badge> )
          </h5>
          <h5>
            <feather-icon
              icon="PlayIcon"
            />
            <strong class="text-primary">SEMESTRE : </strong>{{ dataTaller.semestre.nombre }}
          </h5>

        </b-col>
        <b-col
          lg="5"
          md="5"
          sm="12"
        >
          <h5 class="">
            <feather-icon
              icon="PlayIcon"
            />
            <strong class="text-primary">DOCENTE : </strong>{{ dataTaller.profesor.name }}
          </h5>

        </b-col>
      </b-row>
    </b-card>
    <b-card
      fluid
      class="py-1 px-2"
    >

      <b-row>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <h5 class="">
            <feather-icon
              icon="PlayIcon"
            />
            <strong class="text-primary">LISTA DE ESTUDIANTES : </strong>
          </h5>
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <b-button
            variant="primary"
            class="px-3 float-right"
            size="sm"
            :disabled="dataTaller.estado == 1 ? false : true"
            @click="abrirModal"
          >
            <feather-icon icon="PlusCircleIcon" /> Matricular Estudiante
          </b-button>
        </b-col>

      </b-row>
      <br>
      <b-row>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <label>Mostrar</label>
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
            class="per-page-selector d-inline-block mx-50"
            style="width:auto"
          />
          <label>registros</label>
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
          class="pb-1"
        >
          <b-form-group
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Buscar..."
                size="sm"
              />

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  variant="success"
                  size="sm"
                  @click="filter = ''"
                >
                  Limpiar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="refTablaEstudiantesTaller"
        class="position-relative"
        show-empty
        head-variant="light"
        hover
        small
        :no-provider-filtering="true"
        responsive
        :busy.sync="isBusy"
        :items="myProvider"
        :fields="fields"
        :current-page="currentPage"
        :empty-text="'No hay registros para mostrar'"
        :empty-filtered-text="'No hay registros que coincidan con su solicitud.'"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        caption-top
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #cell(datos_estudiante)="row">
          <strong>Etnia:</strong> <label style="font-size:12px">{{ row.item.persona_alumno.pueblo_indigena && row.item.persona_alumno.pueblo_indigena.nombre || 'No definido' }}</label>
          <br>
          <strong>EP:</strong> <label style="font-size:12px">{{ row.item.escuela.nombre }}</label>
        </template>

        <template #cell(acciones)="row">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :disabled="dataTaller.estado ? false : true"
              @click="evaluar(row.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Evaluación</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="row.item.evaluacion === 'aprobado' ? false : true"
              @click="descargarCertificado( row.item)"
            >

              <feather-icon icon="DownloadIcon" />
              <span class="align-middle ml-50">Descargar certificado</span>
            </b-dropdown-item>
            <b-dropdown-item
              :disabled="dataTaller.estado ? false : true"
              @click="eliminarEstudiante(row.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Retirar del taller</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>
        <template #cell(estudiante)="row">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-primary`"
                :text="avatarText(row.item.persona_alumno.apellidos)"
              />
            </template>

            <strong>{{ row.item.persona_alumno.apellido_paterno }} {{ row.item.persona_alumno.apellido_materno }} {{ row.item.persona_alumno.nombres }}</strong>
            <br>
            <small> DOC. IDENTIDAD: {{ row.item.persona_alumno.numero_documento }}</small>
          </b-media>

        </template>
        <template #cell(index)="row">
          {{ from + row.index }}
        </template>

        <template #cell(evaluacion)="row">
          <b-badge
            pill
            :variant="`light-${resolveEstadoVariant(row.item.evaluacion)}`"
            class="text-capitalize"
          >
            {{ row.item.evaluacion }}
          </b-badge>
        </template>

      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando registros del {{ from }} al {{ to }} de un total de {{ totalRows }} registros</span>
        </b-col>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
        <b-overlay
          :show="show"
          no-wrap
        />
      </b-row>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BMedia, BAvatar, BBreadcrumb, BDropdown, BSpinner, BFormGroup, BInputGroup, BInputGroupAppend, BDropdownItem, BBadge, BFormSelect, BButton, BFormInput, BRow, BCol, BPagination, BTable,
  BOverlay,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import estudiantesStoreModule from './estudiantesStoreModule'
import modalAddEstudiante from './ModalAddEstudiante.vue'
import ModalEvaluacion from './ModalEvaluacion.vue'

export default {
  components: {
    BOverlay,
    modalAddEstudiante,
    BButton,
    BSpinner,
    BAvatar,
    BMedia,
    BBreadcrumb,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BRow,
    BCol,
    BFormSelect,
    BPagination,
    BTable,
    BBadge,
    BCard,
    FeatherIcon,
    ModalEvaluacion,
  },
  props: {
    codigo: {
      type: String,
      default: '#',
    },
    propNameTaller: {
      type: String,
      default: '#',
    },
    pageTitle: {
      type: String,
      default: '#',
    },
    breadcrumb: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      show: false,

      avatarText,
      id: this.$route.params.id,
      estudiantesTaller: [],
      isBusy: false,
      from: 1,
      to: 1,
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 20, 50],
      sortBy: 'id',
      sortDesc: false,
      filter: '',
      filterOn: [],

      dataTaller: { semestre: {}, profesor: {} },

      esProfesor: false,
    }
  },
  created() {
    this.breadcrumb[1].to.params.id = this.$route.params.globalId
  },
  mounted() {
    const data = {
      codigo: this.codigo,
      globalId: this.$route.params.globalId,
      id: this.$route.params.id,
    }

    store.dispatch('estudiantes-store-module/getTaller', data).then(res => {
      this.dataTaller = res.data
    }).catch(() => {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Error al cargar taller',
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    })
  },
  beforeMount() {
    // Pregunto si tiene el rol profesor para ocultar el componnete cabezera
    this.esProfesor = JSON.parse(localStorage.getItem('userData')).roles.some(val => val.name === 'Prof. OCADE')
  },
  methods: {
    actualizarTablaEstudiantes() {
      this.filter = null
      this.$refs.refTablaEstudiantesTaller.refresh()
    },
    async myProvider(ctx) {
      const promise = store.dispatch('estudiantes-store-module/getEstudiantes',
        {
          codigo: this.codigo,
          globalId: this.$route.params.globalId,
          id: this.$route.params.id,
          queryParams: ctx,
        })
      return promise.then(response => {
        const {
          data, total, from, to,
        } = response.data

        const items = data
        this.totalRows = total || 0
        this.from = from || 0
        this.to = to || 0
        this.estudiantesTaller = data
        return items || []
      })
    },

    abrirModal() {
      this.$bvModal.show('modal_add_estudiante')
    },

    evaluar(item) {
      this.$emit('abrirModalEvaluar', item)
    },
    eliminarEstudiante(id) {
      this.$swal({
        title: '¿Esta seguro de retirar al estudiante del taller? ',
        text: 'Esta acción es irreversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, retirar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        const data = {
          codigo: this.codigo,
          globalId: this.$route.params.globalId,
          id: this.$route.params.id,
          matriculaId: id,
        }
        if (result.value) {
          store.dispatch('estudiantes-store-module/deleteEstudianteTaller', data).then(() => {
            this.actualizarTablaEstudiantes()
            this.$swal({
              icon: 'success',
              title: '¡Realizado!',
              text: 'Estudiante retirado',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '¡Error!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    descargarCertificado(item) {
      this.show = true
      this.$http.get(`/servicio-cultural-deportivo/${item.id}/certificado`,
        {
          params: { url_pdf: `${window.location.origin}/descargarPDfCertificado/${item.id}/pdf` },
          responseType: 'blob', // important
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', `CertificadoDIBU${item.id}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.show = false
        })
    },
  },
  setup() {
    const ESTUDIANTES_APP_STORE_MODULE_NAME = 'estudiantes-store-module'

    // Register module
    if (!store.hasModule(ESTUDIANTES_APP_STORE_MODULE_NAME)) store.registerModule(ESTUDIANTES_APP_STORE_MODULE_NAME, estudiantesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ESTUDIANTES_APP_STORE_MODULE_NAME)) store.unregisterModule(ESTUDIANTES_APP_STORE_MODULE_NAME)
    })
    const fields = [

      { key: 'index', label: 'N°', sortable: true },
      { key: 'estudiante', label: 'Estudiante', sortable: true },
      { key: 'datos_estudiante', label: 'datos estudiante', sortable: true },
      { key: 'evaluacion', label: 'evaluacion' },
      { key: 'acciones', label: 'acciones' },
    ]

    const resolveEstadoVariant = status => {
      if (status === 'sin evaluar') return 'secondary'
      if (status === 'aprobado') return 'success'
      if (status === 'desaprobado') return 'danger'
      return 'primary'
    }
    return {
      fields,
      resolveEstadoVariant,
    }
  },
}
</script>
