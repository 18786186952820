<template>
  <div>
    <b-modal
      id="modal_evaluacion_taller"
      title="Evaluación"
      size="lg"
      no-close-on-backdrop
      :hide-footer="true"
      no-close-on-esc
      hide-header-close
    >
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="FileTextIcon"
              size="18"
            />
            <span class="align-middle ml-50">Seleccionar evaluación: </span>
          </b-card-title>
        </b-card-header>
        <b-card-body>

          <b-form
            enctype="multipart/form-data"
            @submit.prevent="formSubmit"
          >
            <b-row>
              <b-col md="12">
                <b-form-group
                  label=""
                  label-for="input-1"
                >
                  <b-form-radio-group
                    v-model="resultadoEvaluacion"
                    :options="options"
                    class="demo-inline-spacing"
                    name="radio-inline"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="12"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  type="submit"
                >
                  <feather-icon
                    icon="SaveIcon"
                    size="15"
                    class="mr-1"
                  />Guardar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card-body>
      </b-card>
    </b-modal>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BButton, BForm, BCardHeader, BCardTitle, BCardBody, BFormRadioGroup,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BRow, BCol, BFormGroup, BButton, BForm, BCardHeader, BCardTitle, BCardBody, BFormRadioGroup,
  },
  props: {
    codigo: {
      type: String,
      default: '#',
    },
  },
  data() {
    return {
      estudianteNombre: '',
      idEstudianteMatricula: null,
      resultadoEvaluacion: null,

      options: [
        { text: 'Sin evaluar', value: 'sin evaluar' },
        { text: 'Aprobado', value: 'aprobado' },
        { text: 'Desaprobado', value: 'desaprobado' },
      ],
    }
  },
  created() {
    this.$parent.$on('abrirModalEvaluar', this.abrirModalEvaluar)
  },
  methods: {
    abrirModalEvaluar(item) {
      this.estudianteNombre = `${item.persona_alumno.apellido_paterno}${item.persona_alumno.apellido_materno}${item.persona_alumno.nombres}`
      this.resultadoEvaluacion = item.evaluacion
      this.idEstudianteMatricula = item.id
      this.$bvModal.show('modal_evaluacion_taller')
    },
    formSubmit() {
      const data = {
        codigo: this.codigo,
        globalId: this.$route.params.globalId,
        id: this.$route.params.id,
        matriculaId: this.idEstudianteMatricula,
        data: {
          evaluacion: this.resultadoEvaluacion,
        },
      }
      store.dispatch('estudiantes-store-module/updateEstudianteTaller', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Evaluación exitosa!',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.$emit('actualizarTablaEstudiantes')
          this.$bvModal.hide('modal_evaluacion_taller')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: '¡Error en la evaluación!',
              icon: 'XOctagonIcon',
              variant: 'danger',
            },
          })
        })
    },
  },

}
</script>
