<template>
  <div>
    <b-modal
      id="modal_add_estudiante"
      ok-only
      size="lg"
      class="p-5"
      title="Buscar Estudiante"
    >
      <h2
        for=""
        class="mt-1 text-primary text-center"
      >BUSCAR ESTUDIANTE</h2>

      <b-row class="text-center">
        <b-col
          md="2"
          class="my-1"
        />
        <b-col
          md="8"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <b-input-group size="lg">
              <b-form-input
                v-model="cadena_a_buscar"
                type="search"
                placeholder="Buscar por DNI o Apellidos y nombres"
                @input="buscarParticipante"
              />

              <b-input-group-append>
                <b-button
                  variant="success"
                  size="sm"
                  @click="buscarParticipante"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="20"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <div v-if="mensajeMinimo">
              <label class="text-danger pt-1"><b-badge variant="light-danger">escribir mínimo 5 caracteres</b-badge> </label>
            </div>
            <div v-else-if="mensajeMinimo === false && m_participantes.length === 0">
              <label class="text-danger pt-1"><b-badge variant="light-warning">No se encontraron resultados</b-badge></label>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          md="2"
          class="my-1"
        />
      </b-row>

      <b-table
        v-if="m_participantes.length>0"
        :items="m_participantes"
        :fields="m_fieldParticipantes"
        :busy.sync="isBusy"
        size="sm"
        sticky-header="300px"
        small
        head-variant="light"
      >
        <template #cell(persona)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-primary`"
                :text="avatarText(data.item.persona.apellidos)"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">N° DNI: {{ data.item.persona.numero_documento }}</small>
          </b-media>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Cargando...</strong>
          </div>
        </template>

        <template #cell(pueblo_indigena)="row">
          {{ row.item.persona.pueblo_indigena && row.item.persona.pueblo_indigena.nombre }}
        </template>
        <template #cell(actions)="row">
          <b-button
            size="sm"
            class="mr-1"
            variant="outline-success"
            @click="adicionarEstudiante(row.item)"
          >
            matricular <feather-icon
              icon="CheckIcon"
              size="14"
            />
          </b-button>
        </template>
      </b-table>
      <!-- _______________ -->

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('modal_add_estudiante')"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BTable, BRow, BCol, BInputGroup, BFormInput,
  BInputGroupAppend, BMedia, BLink, BAvatar, BBadge,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BMedia,
    BLink,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BBadge,
  },
  props: {
    estudiantesTaller: {
      type: Array,
      default: () => [],
    },
    codigo: {
      type: String,
      default: '#',
    },
  },
  data() {
    return {
      avatarText,
      m_participantes: [],
      cadena_a_buscar: '',
      isBusy: false,
      mensajeMinimo: false,
      m_fieldParticipantes: [
        {
          key: 'persona', label: 'persona',
        },
        {
          key: 'pueblo_indigena', label: 'Pueblo Indigena', sortable: true, sortDirection: 'asc',
        },
        { key: 'actions', label: 'Acciones' },
      ],
    }
  },

  computed: {
    ...mapState('actividades_depor', ['participantes', 'tabla', 'talleresSegunDeporte', 'tituloModal', 'tituloBoton', 'guardarEditar', 'cursoTallerNuevo']),
  },
  methods: {
    buscarParticipante() {
      if (this.cadena_a_buscar.length < 5) {
        this.m_participantes = []
        this.mensajeMinimo = true
      } else if (this.cadena_a_buscar.length >= 5) {
        this.mensajeMinimo = false
        this.m_participantes = []

        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          store.dispatch('estudiantes-store-module/buscarEstudiante', this.cadena_a_buscar).then(response => {
            this.m_participantes = response.data.data
          }).catch(() => {
            this.toastSuccess('Error en buscar participantes', 'danger', 'Error')
          })
        }, 500)
      } else {
        this.mensajeMinimo = true
      }
    },
    toastSuccess(text, tipo, titulo) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titulo,
          icon: 'BellIcon',
          text,
          variant: tipo,
        },
      })
    },
    adicionarEstudiante(item) {
      const data = {
        usuario_id: item.id,
      }

      if (this.verificar_existencia_participante(data)) {
        this.toastSuccess('¡Participante Duplicado!', 'danger', 'Advertencia')
      } else {
        const data2 = {
          codigo: this.codigo,
          globalId: this.$route.params.globalId,
          id: this.$route.params.id,
          data,
        }
        store.dispatch('estudiantes-store-module/addEstudianteTaller', data2).then(() => {
          this.toastSuccess('Estudiante agregado!!', 'success', 'Éxito')
          this.$emit('actualizarTablaEstudiantes')
        })
      }
    },
    verificar_existencia_participante(data) {
      let resultado = false
      this.estudiantesTaller.forEach(value => {
        if (value.usuario_id === data.usuario_id) { resultado = true }
      })

      return resultado
    },

  },

}
</script>
