import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getEstudiantes(ctx, {
      codigo, globalId, id, queryParams,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${id}/matricula`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEstudianteTaller(ctx, {
      codigo, globalId, id, data,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${id}/matricula`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEstudianteTaller(ctx, {
      codigo, globalId, id, matriculaId, data,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${id}/matricula/${matriculaId}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteEstudianteTaller(ctx, {
      codigo, globalId, id, matriculaId,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${id}/matricula/${matriculaId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    buscarEstudiante(ctx, cadenaBuscar) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/buscar-alumno?query=${cadenaBuscar}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTaller(ctx, { codigo, globalId, id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-cultural-deportivo/${codigo}/global/${globalId}/taller/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },

}
